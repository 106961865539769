.picklist-button {
  margin-right: 10px;
}

.filter-tag.ant-tag {
  white-space: break-spaces;
}

.pick-list-table {
  display: none;

  &.visible {
    display: block;
  }
}

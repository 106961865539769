@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
}

html, body {
  min-height: 100vh;
  height: 100%;
}

#root {
  height: 100%;
}

a {
  text-decoration: none;
  color: #000;
}

p {
  line-height: 1.7;
}

ul {
  list-style: none;
}

li {
  line-height: 2.2;
}

h1,
h2,
h3 {
  font-weight: 600;
  margin-bottom: 10px;
  padding-top: 10px;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.dashboard {
  display: flex;

  flex-grow: 1;

  &__sidebar {
    max-width: 300px;
    min-width: 300px;
    width: 100%;
    position: relative;
    z-index: 33;
    height: 100%;
    color: #ffffff;
    background: #303956;

    a {
      color: #ffffff;
      text-align: left;
      margin-bottom: 10px;
      margin-left:20px;
      font-size: 20px;
      display: block;
      line-height: 1.5;

      &.active,
      &:hover {
        color: #acb6bf80;
        transition: color .2s;
      }
    }

    &--title-nav {
      text-align: left;
      color: #ffffff;
      font-size: 20px;
      margin-bottom: 40px;
    }

    &--fixed {
      position: fixed;
      background: #081A51;
      left: 0;
      top: 0;
      z-index: 33;
      display: flex;
      flex-direction: column;
      padding: 20px;
      height: 100%;
      max-width: 300px;
      width: 100%;
    }
  }

  &__content {
    padding: 20px;
    flex-grow: 1;
    position: relative;

    &--title {
      text-align: left;
    }
  }
}

.ant-form-horizontal .ant-form-item-label {
  text-align: left;
}

.ant-table-thead>tr>th {
    font-weight: 800;
    background-color: #303956;
    color: #ffffff;

}
.login {
  &-page {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f9f9f9;
  }

  &-form {
    box-shadow: 0 0 20px rgba(0,0,0,0.07);
    border-radius: 5px;
    padding: 20px;
    background: #ffffff;

    .ant-form-item {
      margin-bottom: 15px;
    }

    .ant-form-item-label {
      padding-bottom: 0;
    }
  }
}

.add-form {
  max-width: 600px;

  .ant-form-item-label {
    text-align: left;
  }
}

.parent-link {
  margin-bottom: 10px;
}

.badge-color-picker {
  width: 100% !important;

  input {
    display: none;
  }

  div[title="#FFFFFF"] {
    box-shadow: 0 0 1px #000000;
  }
}

.sub-menu {
  height: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: #212b47;
  padding: 0;
  transition: .2s padding;

  a {
    border-bottom: 1px solid #303956;
    padding: 5px 20px;
    margin: 0;
    width: 100%;

    &:last-child {
      border-bottom: 0;
    }
  }
}

.row-repeater {
  .ant-form-item-label {
    width: 100%;
  }
}

.ant-table-cell.order_id {
  display: none;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin-left: 200px;
  border-bottom: 1px solid #e6e6e6;
}

.header ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header ul li {
  margin-left: 20px;
}

.header ul li a {
  display: flex;
  align-items: center;
}

.header ul li a:hover {
  color: #777;
}

.header ul li a svg {
  margin-right: 5px;
}

.heading {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 50px;
  padding: 0 20px;
}

.heading p {
  color: #828282;
}

.goals {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.goal {
  background-color: #f4f4f4;
  margin: 10px 0;
  padding: 20px 0 10px;
  position: relative;
}

.goal .close {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
  border: none;
  background: none;
}

.form,
.content {
  width: 70%;
  margin: 0 auto;
  background-color: #f2f2f2;
}

.form-group {
  margin-bottom: 10px;
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  margin-bottom: 10px;
  font-family: inherit;
}

.form-group label {
  text-align: left;
  display: block;
  margin: 0 0 5px 3px;
}

.btn {
  padding: 10px 20px;
  border: 1px solid #000;
  border-radius: 5px;
  background: #000;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  appearance: button;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn svg {
  margin-right: 8px;
}

.btn-reverse {
  background: #fff;
  color: #000;
}

.btn-block {
  width: 100%;
  margin-bottom: 20px;
}

.btn:hover {
  transform: scale(0.98);
}

.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #000 transparent #555 transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

.filter-block {
  margin-bottom: 20px;
  width: calc(100vw - 257px);
}

.attribute-select {
  width: 100%;

  &__collapse {
    width: 100%;
    margin-top: 20px;

    .ant-collapse-content-box {
      position: relative;
    }
  }

  & > * {
    margin-top: 10px;
  }

  &__attr {

    &--name {
      width: 100%;
      display: block;
    }

    &--choose {
      width: 100%;
    }

    &--delete {
      position: absolute;
      right: 15px;
      top: 0;
      color: #e82e2e;
    }
  }

  &__col {
    width: 49%;

    & > * {
      width: 100%;
    }

    &--left {
      span {
        display: block;
      }
    }
  }
}

.form {
  &-title,
  &-subtitle {
    text-align: center;
    max-width: 430px;
    width: 100%;
    color: #898989;
  }

  &-title {
    font-size: 20px;
  }

  &-subtitle {
    font-size: 14px;
  }
}

.top-border {
  padding-top: 10px;
  margin-top: 10px;
  border-top: 2px solid #f0f0f0;
  max-width: 300px;
  margin-left: auto;
}

.order {
  &-info {
    text-align: right;
    margin-top: 20px;
  }
}

.custom-table {
  th {
    text-align: left;
  }
}

.gallery-image__list {
  max-height: 700px;
  overflow: hidden auto;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    background: #e8e8e8;
  }

  &::-webkit-scrollbar-thumb {
    background: #c9c9c9;
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0 1px 2px rgba(0,0,0, 0.25);
  }
}

.product-image {
  &__wrapper {
    position: relative;
  }

  &__actions {
    position: absolute;
    right: -30px;
    top: 0;

    .delete {
      color: red;
      margin-right: 10px;
    }

    .edit {
      color: #1890ff;
    }

    .delete, .edit {
      cursor: pointer;
      opacity: 1;
      transition: opacity .2s;

      &:hover {
        opacity: .7;
        transition: opacity .2s;
      }
    }
  }
}

body .ant-checkbox-group, .ant-radio-group {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;

  .ant-radio-button-wrapper, .ant-checkbox-wrapper {
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left-width: 1px;

    &:before {
      content: none;
    }

  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .form {
    width: 90%;
  }

  .heading h1 {
    font-size: 2rem;
  }

  .heading p {
    font-size: 1.5rem;
  }
}

.stage_name {
  color: #AAA;
  font-size: xx-small;
}

.custom-panel-header span {
  margin-left: 8px; /* Adjust the spacing as needed */
}

.custom-panel-header  {
  color: white; /* Adjust the spacing as needed */
}

.ant-collapse-arrow{
  color: white; /* Adjust the spacing as needed */
}
.ant-collapse-header {
  background-color: #303956; /* Adjust the spacing as needed */
}

.ant-input-borderless, .ant-input-borderless-disabled, .ant-input-borderless-focused, .ant-input-borderless:focus, .ant-input-borderless:hover, .ant-input-borderless[disabled] {
    background-color: white;
    border: none;
    box-shadow: none;
}

.ant-collapse-content {
  background-color: #f2f2f2 !important; /* Header background color, if needed */
}


.ant-form {
    background-color: #f2f2f2;
    padding-left: 20px;
}


.ant-form-item-control-input {
  margin-bottom: 10px;
}

.ant-input {
  width:100%;
}


.form-table {

  &__head {
    display: flex;
    border-bottom: 1px solid #f0f0f0;
    background-color: #FAFAFA;

    &--th {
      padding: 20px;

      &:not(:last-child) {
        border-right: 1px solid #f0f0f0;
      }
    }
  }

  &__row {
    border: 1px solid #f0f0f0;
    margin-bottom: 20px;
  }

  &__col {
    padding: 20px;

    .ant-form-item {
      margin: 0;
    }

    &:not(:last-child) {
      border-right: 1px solid #f0f0f0;
    }
  }
}
